import { Injectable } from '@angular/core'
import { LocationState } from '@core/models/shared/location.model'
import { Location } from '@angular/common'
import { Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class LocationHelper {

	constructor(
		private location: Location,
		private router: Router
	) {}

	public getState(): LocationState {
		return this.location.getState() as LocationState
	}

	public routeWithState(route: string[], state: LocationState) {
		this.router.navigate(route, { state })
	}
}
